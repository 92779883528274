import React, { useEffect, useState } from 'react';

/**
 * Material UI
 */
import { Box, Grid, Typography, TextField, IconButton, Icon, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * React Sppech Kit
 */
import { useSpeechSynthesis } from 'react-speech-kit';

/**
 * Application
 */
import { SectionHeader } from './controls/SectionHeader';
import { getVoiceLanguageCodeByText } from '../../../common/functions/LanguageUtils';
import { NumericControl } from '../../../common/components/controls';

export const SECTION_DICTATION_EXAMPLAR = {
    title: "Title of content",
    questions: [
        {
            answer: "The selected vocabulary",
        },
        {
            answer: "The selected vocabulary",
        }
    ]
};

export const generateDictationPrompt = (numberOfQuestions, difficulty, languageCode) => {
    switch (languageCode) {
        case "en":
            return `Can you create choose ${numberOfQuestions} key vocabularies from the above content difficulty level ${difficulty} out of 5. Respond in Englis with the format of the examplar below only without anything else. \n ${JSON.stringify(SECTION_DICTATION_EXAMPLAR, null, 3)}`;
        case "zh":
            return `請從上文中挑選${numberOfQuestions}個詞彙（非專有名詞），難度10份之${difficulty * 2}。 用繁體中文回答，只回答內容成以下格式. \n ${JSON.stringify(SECTION_DICTATION_EXAMPLAR, null, 3)}`;
    }
}


const EMPTY_SECTION_ATTEMPT = { score: 0, answers: [] };
export function DictationSection(props) {
    const classes = makeStyles(theme => ({
        textBlock: {
            display: "inline",
            whiteSpace: "pre-wrap",
            verticalAlign: "bottom",
            // lineHeight: "2.5em",
        },
        questionGrid: {
            display: "flex",
            textAlign: "left"
        },
        questionNumberMarker: {
            minWidth: theme.spacing(5),
        },
        questionBox: {
            width: "100%"
        },
        correctAnswerBox: {
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            marginLeft: theme.spacing(-1),
            borderRadius: 10,
            width: "auto"
        },
        correctAnswerText: {
            color: theme.palette.success.main,
        },
        incorrectAnswerText: {
            color: theme.palette.error.main,
        },
        playIconButtonCurrentlyPlaying: {
            backgroundColor: theme.palette.primary.main
        }
    }))();
    const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis();
    const { sectionIndex, section, updateSectionAttempt, showScore, showAnswer } = props;
    const sectionAttempt = Object.assign({}, { numberOfAnswers: section.questions.length }, EMPTY_SECTION_ATTEMPT, props.sectionAttempt);
    // Default Play Config Settings
    const [playConfig, setPlayConfig] = useState({
        isPlaying: false,
        showControl: false,
        repeat: 1,
        interval: 60,
        speed: 1,
        nextIndex: [0, 0]
    });
    const [promises, setPromises] = useState([]);

    const updateAnswer = (questionIndex, answer) => {
        let originalSectionAttempt = Object.assign({}, sectionAttempt);
        let originalAnswers = Object.assign([], originalSectionAttempt.answers)
        // As Radio Group doesn't support integer as value, so pass it as String and convert back
        originalAnswers[questionIndex] = answer;
        originalSectionAttempt.answers = originalAnswers;
        originalSectionAttempt.score = originalSectionAttempt.answers.filter((answer, aIndex) => answer === section.questions[aIndex].answer).length;
        updateSectionAttempt(sectionIndex, originalSectionAttempt);
    }

    const playNextWord = () => {
        let qIndex = playConfig?.nextIndex[0];
        let rIndex = playConfig?.nextIndex[1];
        if (Boolean(playConfig?.isPlaying) && qIndex < section.questions.length && rIndex < playConfig?.repeat) {
            // Speak the word
            let languageCodes = getVoiceLanguageCodeByText(section.questions[qIndex].answer);
            let text = section.questions[qIndex].answer;
            speak({ text: qIndex + 1, rate: (0.5 * playConfig?.speed), voice: voices.filter(v => languageCodes.includes(v.lang))[0] })
            setTimeout(
                () => speak({ text: text, rate: (0.5 * playConfig?.speed), voice: voices.filter(v => languageCodes.includes(v.lang))[0] }),
                1000);
            if (rIndex < (playConfig?.repeat - 1)) {
                // Repeat the same word
                rIndex += 1;
            } else if (qIndex < (section.questions.length - 1)) {
                // Goto the next word
                qIndex += 1;
                rIndex = 0;
            } else {
                // Stop playing after the last word
                qIndex = 0;
                rIndex = 0;
            }
            let processId = setTimeout(
                () => setPlayConfig({ ...playConfig, isPlaying: !(qIndex === 0 && qIndex === 0), nextIndex: [qIndex, rIndex] }),
                playConfig?.interval * 1000);
            setPromises([...promises, ...[processId]]);
        }
    }

    useEffect(() => {
        promises.forEach(pId => clearTimeout(pId));
        playNextWord();
    }, [playConfig?.isPlaying, playConfig?.nextIndex])

    return (
        Boolean(sectionAttempt) ?
            // TODO: Check why using className="print-table" in the first place
            <Grid container spacing={3}>
                <SectionHeader section={section}
                    sectionIndex={sectionIndex}
                    sectionAttempt={sectionAttempt}
                    showScore={showScore}
                    showAnswer={showAnswer}
                    sectionControl={
                        <Box displayPrint="none" component="span">
                            <Button size="small"
                                onClick={() => setPlayConfig({ ...playConfig, showControl: !playConfig?.showControl })}
                                endIcon={<Icon>{Boolean(playConfig?.showControl) ? "expand_less" : "expand_more"}</Icon>}>Play All</Button>
                        </Box>
                    } />
                {/* Play All Control */}
                {Boolean(playConfig?.showControl) &&
                    <Grid item xs={12}>
                        <Box displayPrint="none" textAlign="right">
                            <PlayAllControl
                                isPlaying={playConfig?.isPlaying}
                                isPaused={!playConfig?.isPlaying && !(playConfig?.nextIndex[0] === 0 && playConfig.nextIndex[1] === 0)}
                                restart={() => setPlayConfig({ ...playConfig, isPlaying: true, nextIndex: [0, 0] })}
                                resume={() => setPlayConfig({ ...playConfig, isPlaying: true })}
                                stop={() => setPlayConfig({ ...playConfig, isPlaying: false, nextIndex: [0, 0] })}
                                pause={() => setPlayConfig({ ...playConfig, isPlaying: false })}
                                playNext={() => setPlayConfig({ ...playConfig, nextIndex: [playConfig?.nextIndex[0] + 1, 0] })}
                                playPrevious={() => setPlayConfig({ ...playConfig, nextIndex: [playConfig?.nextIndex[0] - 1, 0] })}
                                startAt={playConfig?.nextIndex[0] + 1}
                                setStartAt={(value) => setPlayConfig({ ...playConfig, isPlaying: false, nextIndex: [value - 1, 0] })}
                                maxStartAt={section.questions.length}
                                repeat={playConfig?.repeat}
                                setRepeat={(value) => setPlayConfig({ ...playConfig, repeat: value })}
                                interval={playConfig?.interval}
                                setInterval={(value) => setPlayConfig({ ...playConfig, interval: value })}
                                speed={playConfig?.speed}
                                setSpeed={(value) => setPlayConfig({ ...playConfig, speed: Math.round(value * 10) / 10 })}
                            />
                        </Box>
                    </Grid>}
                {/* End: Play All Control */}

                {/* Display Each Questions */}
                {section.questions.map((question, qIndex) =>
                    <Grid key={`question-${section.id}-${qIndex}`} item xs={12} md={4} lg={3} className={classes.questionGrid}>
                        <Box display="flex" justifyContent="start">
                            <Box mr={2}>
                                <Typography className={classes.textBlock}>{qIndex + 1}. </Typography>
                            </Box>
                            <Box className={classes.questionBox} m={0} verticalAlign="middle">
                                <Box display='flex'>
                                    {showAnswer ?
                                        <React.Fragment>
                                            <Typography component="span" className={sectionAttempt.answers[qIndex] === section.questions[qIndex].answer ? classes.correctAnswerText : classes.incorrectAnswerText}>{sectionAttempt.answers[qIndex]}</Typography>
                                            {sectionAttempt.answers[qIndex] !== section.questions[qIndex].answer ?
                                                <Box component="span" ml={2}>
                                                    <Typography component="span" className={classes.correctAnswerBox}>{section.questions[qIndex].answer}</Typography>
                                                </Box> : null}
                                        </React.Fragment>
                                        : <TextField fullWidth onChange={(e) => updateAnswer(qIndex, e.target.value)} />}
                                    <Box displayPrint="none">
                                        <IconButton size="small"
                                            className={Boolean(playConfig?.isPlaying) && Boolean(qIndex === playConfig?.nextIndex[0]) ? classes.playIconButtonCurrentlyPlaying : null}
                                            onClick={() => {
                                                let languageCodes = getVoiceLanguageCodeByText(section.questions[qIndex].answer);
                                                speak({ text: section.questions[qIndex].answer, rate: 0.5, voice: voices.filter(v => languageCodes.includes(v.lang))[0] })
                                            }}>
                                            <Icon>play_arrow</Icon>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                )}
                {/* End: Display Each Questions */}
            </Grid > : null
    )
}


const PlayAllControl = (props) => {
    const { isPlaying, isPaused, restart, resume, stop, pause, playNext, playPrevious, startAt, setStartAt, maxStartAt, repeat, setRepeat, interval, setInterval, speed, setSpeed } = props;
    const classes = makeStyles(theme => ({
        label: {
            color: theme.palette.grey[500]
        },
        controlContainter: {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: theme.spacing(1),
            minWidth: 300
        }
    }))();
    return (
        <Box display="inline-flex" className={classes.controlContainter} alignItems="center" p={1}>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" p={1}>
                <Box display="flex" flexDirection="column" alignItems="center" px={0.5}>
                    <Typography variant="caption" className={classes.label}>Start at</Typography>
                    <NumericControl
                        value={startAt}
                        min={1} max={maxStartAt} onChange={setStartAt} />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" px={0.5}>
                    <Typography variant="caption" className={classes.label}>Repeat</Typography>
                    <NumericControl
                        value={repeat}
                        min={1} max={5} onChange={setRepeat} />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" px={0.5}>
                    <Typography variant="caption" className={classes.label}>Interval (s)</Typography>
                    <NumericControl
                        value={interval}
                        min={10} max={120} step={5} onChange={setInterval} />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" px={0.5}>
                    <Typography variant="caption" className={classes.label}>Speed</Typography>
                    <NumericControl
                        value={speed}
                        min={0.5} max={2} step={0.1} onChange={setSpeed} />
                </Box>
            </Box>
            <Divider orientation="vertical" flexItem={true} />
            <Box pl={1}>
                <IconButton
                    disabled={!isPlaying || startAt === 1}
                    size="small"
                    color="primary"
                    onClick={playPrevious}>
                    <Icon fontSize="large">skip_previous_circle</Icon>
                </IconButton>
                {Boolean(isPlaying) ?
                    <React.Fragment>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={pause}>
                            <Icon fontSize="large">pause_circle</Icon>
                        </IconButton>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={stop}>
                            <Icon fontSize="large">stop_circle</Icon>
                        </IconButton>
                    </React.Fragment> :
                    <React.Fragment>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={Boolean(isPaused) ? resume : restart}>
                            <Icon fontSize="large">play_circle</Icon>
                        </IconButton>
                        <IconButton
                            disabled={!Boolean(isPaused)}
                            size="small"
                            color="primary"
                            onClick={restart}>
                            <Icon fontSize="large">replay_circle_filled</Icon>
                        </IconButton>
                    </React.Fragment>}
                <IconButton
                    disabled={!isPlaying || startAt === maxStartAt}
                    size="small"
                    color="primary"
                    onClick={playNext}>
                    <Icon fontSize="large">skip_next_circle</Icon>
                </IconButton>
            </Box>
        </Box>
    );
}
import React, { useState, useContext, useEffect, useRef } from 'react';

/**
 * AMR Player / Recorder
 */
import BenzAMRRecorder from 'benz-amr-recorder'

/**
 * Firebase
 */
import { storage } from '../../serviecs/firebase';

/**
 * Material UI
 */
import { Box, Icon, IconButton, Typography, Slider, Badge } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

/**
 * QR Code
 */
import QRCode from 'qrcode.react';

/**
 * Application
 */
import { UserContext } from '../../../App';

/**
 * Constant
 */
export const SUPPORTED_MEDIA = {
    image: { key: "image", icon: "image" },
    audio: { key: "audio", icon: "mic" },
    video: { key: "video", icon: "videocam" }
};

/**
 * 
 * @param {file:File} fileBlob 
 * @param {type:String, extension:String, url:String} callback
 */
export function uploadFileToFirebaseStorage(user, fileBlob, callback) {
    // Upload to Firebase Cloud Storage
    let [mime, extension] = fileBlob.type.split('/')
    // Replace spaces in file name with dash
    // let fileName = fileBlob.name;
    let fileName = fileBlob.name.replaceAll(" ", "-");
    storage.ref().child(`${user.id}/${mime}/${new Date().getTime()}-${fileName}`).put(fileBlob).then(snapshot => {
        snapshot.ref.getDownloadURL().then(url => {
            callback(extension, url, snapshot?.metadata);
        });
    });
}

/**
 * Generic Button for all Media
 * @param {{type:String, iconKey:String}} props 
 */
export function UploadMediaToFirebaseStorageButton(props) {
    const { type, iconKey, callback, ...others } = props;
    const { user } = useContext(UserContext);
    const elementId = `add-${type}-button`;

    return (
        Boolean(user) &&
        <React.Fragment>
            <input id={elementId} type="file" accept={`${type}/*`} style={{ display: "none" }}
                onChange={e => {
                    uploadFileToFirebaseStorage(user, e.target.files[0], callback);
                    e.target.value = null;
                }} />
            <label htmlFor={elementId}>
                <IconButton size="small" component="span" {...others}>
                    <Icon size="small" style={{ fontSizes: "18px" }}>{SUPPORTED_MEDIA[type].icon}</Icon>
                </IconButton>
            </label>
        </React.Fragment>
    );
}

/**
 * TODO: Enhance the audio play to support mini
 * @param {*} props 
 */
export function AudioPlayer(props) {
    const { src, extension, showControls, toggleControls } = props;
    let playerElement = <div>Audio extension '{extension}' not support.</div>;
    if (extension === "amr") {
        // Custom Audio Player for .amr extension
        playerElement = <AmrAudioPlayer src={src} showControls={showControls} toggleControls={toggleControls} />;
    } else if (["mpeg", "ogg", "wav"].includes(extension)) {
        // Standard HTML 5 Audio Player
        playerElement = <Html5AudioPlayer src={src} extension={extension} showControls={showControls} toggleControls={toggleControls} />;
    }
    return (
        <React.Fragment>
            {/* Audio Player */}
            <Box displayPrint="none">
                {playerElement}
            </Box>
            {/* End: Audio Player */}
            {/* QR Code */}
            <Box display="none" displayPrint="inline-block">
                <Box m={1} style={{ display: "inline-block" }}>
                    <Badge badgeContent={<Icon>play_arrow</Icon>} color="primary" anchorOrigin={{ vertical: "top", horizontal: "left" }}>
                        <QRCode style={{ verticalAlign: "middle" }} value={src} size={80} />
                    </Badge>
                </Box>
            </Box>
            {/* End: QR Code */}
        </React.Fragment>
    );
}

/**
 * Audio Player Box
 */
const AudioPlayerBox = withStyles(theme => ({
    root: {
        backgroundColor: grey[100],
        borderRadius: theme.spacing(3),
        padding: theme.spacing(1.5),
        width: 300,
        display: "inline-block"
    }
}))(Box);

/**
 * Override style of Slider
 * 1. No thumb
 * 2. Thicker rail and track
 * 3. Grey in color and right margin
 */
const AudioPositionSlider = withStyles(theme => ({
    root: {
        color: grey[700],
        marginRight: theme.spacing(1)
    },
    thumb: {
        display: "none"
    },
    rail: {
        height: 4,
        borderRadius: 10
    },
    track: {
        height: 4,
        borderRadius: 10
    }
}))(Slider);

/**
 * A custom made Audio Recorder (record in .amr format)
 * 
 * onFinishRecord(url): pass back the URL 
 */
export function AmrAudioRecorder(props) {
    const [amrRecorder, setAmrRecorder] = useState();
    const [isRecording, setRecording] = useState(false);
    const { user } = useContext(UserContext);
    const { onFinishRecord } = props;

    const startRecord = () => {
        let amrRecorder = new BenzAMRRecorder();
        amrRecorder.onStartRecord(() => {
            console.log("On Start Record...");
            setRecording(true);
        })
        amrRecorder.onFinishRecord(() => {
            console.log("On Finish Record...");
            setRecording(false);
            amrRecorder.getBlob().name = "uploaded-audio";
            uploadFileToFirebaseStorage(user, amrRecorder.getBlob(), (extension, url) => {
                // If callback is provided
                if (Boolean(onFinishRecord)) {
                    onFinishRecord(extension, url);
                    setAmrRecorder(null);
                }
            })
        });
        setAmrRecorder(amrRecorder);
        amrRecorder.initWithRecord().then(() => {
            amrRecorder.startRecord();
        });
    };
    const finishRecord = () => {
        console.log("Stop recording")
        amrRecorder.finishRecord();
    }
    return (
        <Box component="span">
            <RecordButton isRecording={isRecording}
                startRecord={startRecord}
                stopRecord={finishRecord} />
        </Box>
    )
}

function RecordButton(props) {
    const classes = makeStyles((theme) => ({
        recording: {
            backgroundColor: theme.palette.error.main
        }
    }))();
    const { isRecording, startRecord, stopRecord, play, stop } = props;
    return (
        <Box component="span">
            <IconButton size="small" className={isRecording ? classes.recording : null}>
                {Boolean(isRecording) ?
                    <Icon size="small" onClick={stopRecord}>stop</Icon> :
                    <Icon size="small" onClick={startRecord}>mic</Icon>}
            </IconButton>
            {false &&
                <IconButton size="small" className={isRecording ? "recording" : null}>
                    <Icon size="small" onClick={play}>play_arrow</Icon>
                </IconButton>}
            {false &&
                <IconButton size="small" className={isRecording ? "recording" : null}>
                    <Icon size="small" onClick={stop}>pause</Icon>
                </IconButton>}
        </Box>
    );
}

/**
 * A custom made Audio Play for playing .amr file
 */
export function AmrAudioPlayer(props) {
    const { src, showControls } = props;
    const [amrPlayer, setAmrPlayer] = useState(null);
    const [isPlaying, setPlaying] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(0);
    useEffect(() => {
        const amrPlayer = new BenzAMRRecorder();
        amrPlayer.initWithUrl(src).then(() => {
            setAmrPlayer(amrPlayer);
            amrPlayer.onPlay(() => {
                setPlaying(true);
                setTimeout(() => refreshCurrentPosition(amrPlayer), 200);
            });
            amrPlayer.onResume(() => {
                setPlaying(true);
                setTimeout(() => refreshCurrentPosition(amrPlayer), 200);
            });
            amrPlayer.onStop(() => {
                setPlaying(false);
            });
            amrPlayer.onPause(() => {
                setPlaying(false);
            });
        });
    }, [src]);

    const togglePlay = () => {
        if (Boolean(amrPlayer)) {
            amrPlayer.playOrPauseOrResume();
        }
    }

    const refreshCurrentPosition = (amrPlayer) => {
        if (amrPlayer.isPlaying()) {
            setCurrentPosition(amrPlayer.getCurrentPosition());
            setTimeout(() => refreshCurrentPosition(amrPlayer), 100);
        }
    }

    const setPosition = (newPosition) => {
        if (Boolean(amrPlayer)) {
            amrPlayer.setPosition(newPosition);
            setCurrentPosition(newPosition);
        }
    }

    // border={1} borderRadius={15} borderColor={"grey"} p={1} textAlign="center"
    return (
        Boolean(amrPlayer) &&
        (Boolean(showControls) ?
            // With Controls
            <AudioPlayerBox>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <PlayButton isPlaying={isPlaying} togglePlay={togglePlay} />
                    <Box component="span" ml={1}>
                        <TimeDisplay lengthInSeconds={currentPosition} />
                    </Box>
                    <Box component="span" mx={0.5}>
                        <Typography variant="body2">/</Typography>
                    </Box>
                    <Box component="span" mr={2}>
                        <TimeDisplay lengthInSeconds={amrPlayer.getDuration()} />
                    </Box>
                    <AudioPositionSlider min={0} max={amrPlayer.getDuration()} step={0.1}
                        value={currentPosition}
                        onChange={(e, newPosition) => setPosition(newPosition)} />
                </Box>
            </AudioPlayerBox> :
            // Play/Pause Button Only
            <PlayButton isPlaying={isPlaying} togglePlay={togglePlay} />)
    );
}

/**
 * Standard HTML 5 Audio Player
 */
function Html5AudioPlayer(props) {
    const { src, extension, showControls } = props;
    const audioPlayerReference = useRef();
    const [isPlaying, setPlaying] = useState(false);
    const togglePlay = () => {
        if (Boolean(audioPlayerReference)) {
            if (isPlaying) {
                audioPlayerReference.current.pause();
            } else {
                audioPlayerReference.current.play();
            }
            setPlaying(!isPlaying);
        }
    }
    return (
        <React.Fragment>
            {/* Standard HTML 5 Audio Player with Controls */}
            <audio ref={audioPlayerReference} controls style={{ display: Boolean(showControls) ? "inline-block" : "none" }}>
                <source src={src}
                    type={`audio/${extension}`}
                    style={{ display: "block", maxWidth: "100%", maxHeight: "20rem", boxShadow: '0 0 0 3px #B4D5FF' }} />
            </audio>
            {/* Play/Pause Button Only */}
            {!Boolean(showControls) && <PlayButton isPlaying={isPlaying} togglePlay={togglePlay} />}
        </React.Fragment>
    );
}

function PlayButton(props) {
    const { isPlaying, togglePlay } = props;
    return (
        <Box component="span">
            <IconButton size="small">
                <Icon size="small" onClick={togglePlay}>{isPlaying ? "pause" : "play_arrow"}</Icon>
            </IconButton>
        </Box>
    );
}

function TimeDisplay(props) {
    const lengthInSeconds = Math.ceil(props.lengthInSeconds || 0);
    const hours = Math.floor(lengthInSeconds / 3600);
    const minutes = Math.floor(lengthInSeconds / 60);
    const seconds = lengthInSeconds % 60;
    return (
        <Box display="flex" justifyContent="start">
            {(hours > 0) && <Typography variant="body2">{hours}:</Typography>}
            <Typography variant="body2">{(hours > 0 && minutes < 10) && 0}{minutes}:</Typography>
            <Typography variant="body2">{(seconds < 10) && 0}{seconds}</Typography>
        </Box>
    )
}
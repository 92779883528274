import React, { useContext, useState } from 'react';

import { Box, Button, Grid, Icon, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { generateDictationPrompt } from "../../app/test-paper/sections/DictationSection";
import { generateFillInBlankPrompt } from "../../app/test-paper/sections/FillInBlankSection";
import { generateMatchingPrompt } from "../../app/test-paper/sections/MatchingSection";
import { generateMultipleChoicePrompt } from "../../app/test-paper/sections/MultipleChoiceSection";
import { AppContext } from '../../App';
import { NumericControl } from '../components/controls';
import { Rating } from '@material-ui/lab';

export const SUPPORTED_SECTION_PROMPT = [
    {
        type: "dictation",
        label: "Dictation",
        promptFunction: generateDictationPrompt,
        responseTransformer: (responseJson) => JSON.parse(responseJson?.output?.text)
    },
    {
        type: "fill-in-blank",
        label: "Fill in Blank",
        promptFunction: generateFillInBlankPrompt,
        responseTransformer: (responseJson) => {
            let responseText = responseJson?.output?.text;
            let section = JSON.parse(responseText);
            let sentences = section?.sentences || [];
            let questions = [];
            sentences.forEach(s => {
                let tokens = s?.sentence.split(s?.vocabulary);
                tokens = tokens.map(t => [{ type: "text", value: t }, { type: "answer", value: s?.vocabulary }]).flat()
                tokens.pop();
                questions.push({
                    blocks: tokens
                })
            });
            return {
                title: section?.title,
                questions: questions
            }
        }
    },
    {
        type: "multiple-choice",
        label: "Multiple Choice",
        promptFunction: generateMultipleChoicePrompt,
        responseTransformer: (responseJson) => JSON.parse(responseJson?.output?.text)
    },
    {
        type: "matching",
        label: "Matching",
        promptFunction: generateMatchingPrompt,
        responseTransformer: (responseJson) => {
            let responseText = responseJson?.output?.text;
            let section = JSON.parse(responseText);
            let vocabularies = section?.vocabularies || [];
            let expressions = vocabularies.map((v, vIndex) => ({ option: vIndex, text: v.explanation }));
            let options = vocabularies.map(v => v.vocabulary);
            return {
                title: section?.title,
                questions: [{
                    expressions: expressions,
                    options: options
                }]
            }
        }
    }
]

/**
 * Call DeepSeep API single prompt
 * 
 * @param {*} words 
 */
export const callDeepSeekApi = async (apiToken, context, question) => {
    try {
        let url = `${process.env.REACT_APP_AI_API}/deepseek/single-prompt`;
        let body = {
            model: "deepseek-chat",
            messages: [
                {
                    role: "user",
                    content: context
                },
                {
                    role: "user",
                    content: question
                }
            ],
            "stream": false
        }
        let response = await fetch(url, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${apiToken}`,
                "Content-Type": "application/json",
                "Accept": "*/*"
            },
            body: JSON.stringify(body)
        });
        if (response.status !== 200) {
            return {
                success: false,
                message: `Error calling DeepSeek API. HTTP status: ${response.status}`
            }
        } else {
            let responseJson = await response.json();
            return responseJson;
        }
    } catch (exception) {
        return {
            success: false,
            message: `Error calling DeepSeek API. Error message: ${exception.stack}`
        }
    }
}

const PROMPT_QUESTION_TIDY_UP_OCR_SCAN = "This is extracted from OCR. Please remove unwanted content and leave only true content and respond. If there is title, please leave the title also. If there is symbol numbering, replace with actual numbering. No need to leave remark though. No need to use markdown.";
export const AskAIMenu = (props) => {
    const { user, languageCode, page, updateAttachmentPage, addAttachmentPageSuggestion, addLoading, removeLoading, toggleCustomizing, disabled = false } = props;
    const { setMessage } = useContext(AppContext);
    const classes = makeStyles(theme => ({
        askAIButton: {
            color: theme.palette.primary.main,
        }
    }))();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = event => {
        setAnchorEl(event.target);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <Button disabled={disabled} className={classes.askAIButton} onClick={handleClick}
                endIcon={<Icon>expand_more</Icon>}>Ask AI</Button>
            <Menu id="ask-ai-menu" keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem key={`ask-ai-tidy-up`}
                    onClick={async () => {
                        handleClose();
                        addLoading("Tidying up content")
                        let responseJson = await callDeepSeekApi(user?.idToken, page?.extractedText, PROMPT_QUESTION_TIDY_UP_OCR_SCAN);
                        removeLoading("Tidying up content");
                        if (responseJson?.success === false) {
                            setMessage(() => responseJson?.message);
                        } else {
                            let responseText = responseJson?.output?.text;
                            addAttachmentPageSuggestion({
                                type: "tidy-up",
                                value: responseText
                            });
                        }
                    }}>
                    <Typography variant="subtitle2">Tidy-up</Typography>
                </MenuItem>
                {/* Generate Sections with AI */}
                {SUPPORTED_SECTION_PROMPT.map((supportedPrompt, spIndex) =>
                    <MenuItem key={`ask-ai-${spIndex}`}
                        onClick={async () => {
                            handleClose();
                            addLoading(`Generating ${supportedPrompt?.label}`)
                            let prompt = supportedPrompt?.promptFunction(10, 2, languageCode);
                            let responseJson = await callDeepSeekApi(user?.idToken, page?.extractedText, prompt);
                            removeLoading(`Generating ${supportedPrompt?.label}`)
                            if (responseJson?.success === false) {
                                setMessage(() => responseJson?.message);
                            } else {
                                addAttachmentPageSuggestion({
                                    type: supportedPrompt.type,
                                    value: supportedPrompt?.responseTransformer(responseJson)
                                })
                            }
                        }}>
                        <Typography variant="subtitle2">Generate {supportedPrompt?.label}</Typography>
                    </MenuItem>
                )}
                {/* Customize Options */}
                <MenuItem key={`ask-ai-customize-options`}
                    onClick={() => {
                        handleClose();
                        toggleCustomizing();
                    }}>
                    <Typography variant="subtitle2">Customize...</Typography>
                </MenuItem>
                {/* End: Customize Options */}
                {/* End: Generate Sections with AI */}
            </Menu>
        </React.Fragment>
    );
}

export const AskAiCustomization = (props) => {
    const { user, languageCode, page, askAiConfig, toggleCustomizing, updateAskAiCustomization, addAttachmentPageSuggestion, addLoading, removeLoading } = props;
    const { setMessage } = useContext(AppContext);
    const generateSuggestions = async () => {
        let asyncGenerateFunctions = [];
        SUPPORTED_SECTION_PROMPT.map(supportedPrompt => {
            let type = supportedPrompt?.type;
            let _customization = (askAiConfig?.customizations || {})[type];
            if (Boolean(_customization)) {
                addLoading(`Generating ${supportedPrompt?.label}`);
                let prompt = supportedPrompt?.promptFunction(_customization?.numberOfQuestions, _customization?.difficulty, languageCode);
                let asyncGenerateFunction = async () => {
                    let responseJson = await callDeepSeekApi(user?.idToken, page?.extractedText, prompt);
                    removeLoading(`Generating ${supportedPrompt?.label}`);
                    if (responseJson?.success === false) {
                        setMessage(() => responseJson?.message);
                    } else {
                        addAttachmentPageSuggestion({
                            type: supportedPrompt.type,
                            value: supportedPrompt?.responseTransformer(responseJson)
                        });
                    }
                }
                asyncGenerateFunctions.push(asyncGenerateFunction());
            }
        });
        await Promise.all(asyncGenerateFunctions);
        toggleCustomizing();
    }
    return (
        <Grid container spacing={1}>
            {SUPPORTED_SECTION_PROMPT.map((supportedPrompt, spIndex) =>
                <Grid key={spIndex} item xs={12} md={6}>
                    <AskAISectionCustomization
                        supportedPrompt={supportedPrompt}
                        customization={askAiConfig?.customizations[supportedPrompt?.type]}
                        updateAskAiCustomization={updateAskAiCustomization}
                        generateSuggestions={generateSuggestions} />
                </Grid>)}
            <Grid item xs={12}>
                <Box textAlign="right" mb={2}>
                    <Button color="primary" onClick={generateSuggestions}>Generate with AI</Button>
                    <Button color="primary" onClick={toggleCustomizing}>Cancel</Button>
                </Box>
            </Grid>
        </Grid>
    );
}

const AskAISectionCustomization = (props) => {
    const { supportedPrompt, customization, updateAskAiCustomization } = props;
    const classes = makeStyles(theme => ({
        sectionLabel: {
            fontSize: "0.9rem",
            fontWeight: 600,
            //color: theme.palette.grey[500]
        },
        propertyLabel: {
            color: theme.palette.grey[500]
        },
        controlContainter: {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: theme.spacing(1),
        },
        controlLabelBox: {
            cursor: "pointer"
        },
        controlLabelBoxSelected: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        controlLabelBoxDisabled: {
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[500]
        }
    }))();
    let selected = Boolean(customization);
    let type = supportedPrompt?.type;
    let onClickToggle = selected ?
        () => updateAskAiCustomization(type, null) :
        () => updateAskAiCustomization(type, Object.assign({}, { numberOfQuestions: 5, difficulty: 1 }));
    return (
        <Box display="flex" className={classes.controlContainter} alignItems="stretch">
            {/* Select/Deselect */}
            <Box display="flex"
                width={250}
                className={[classes.controlLabelBox, (selected ? classes.controlLabelBoxSelected : classes.controlLabelBoxDisabled)]}
                justifyContent="center"
                alignItems="center" p={2}
                onClick={onClickToggle}>
                <Typography variant="caption" className={classes.sectionLabel}>{supportedPrompt?.label}</Typography>
            </Box>
            {/* End: Select/Deselect */}
            {/* Options */}
            <Box display="flex" width="100%" justifyContent="space-evenly" flexWrap="wrap" p={1}>
                {/* Number of Questions */}
                <Box display="flex" flexDirection="column" alignItems="center" px={0.5}>
                    <Typography variant="caption" className={classes.propertyLabel}>Number of Questions</Typography>
                    {selected ?
                        <NumericControl
                            step={1}
                            value={customization?.numberOfQuestions}
                            min={1}
                            max={20}
                            onChange={newNumberOfQuestions => updateAskAiCustomization(type, Object.assign({}, customization, { numberOfQuestions: newNumberOfQuestions }))} /> :
                        <Typography variant='caption' className={classes.propertyLabel}>---</Typography>}
                </Box>
                {/* End: Number of Questions */}
                {/* Difficulty */}
                <Box display="flex" flexDirection="column" alignItems="center" px={0.5}>
                    <Typography variant="caption" className={classes.propertyLabel}>Difficulty</Typography>
                    {selected ?
                        <Rating
                            name={`ask-ai-difficulty-${type}`}
                            value={customization?.difficulty}
                            max={5}
                            color="primary"
                            onChange={(event, newDifficulty) => {
                                updateAskAiCustomization(type, Object.assign({}, customization, { difficulty: newDifficulty }));
                            }}
                        /> :
                        <Typography variant='caption' className={classes.propertyLabel}>---</Typography>}
                </Box>
                {/* End: Difficulty */}
            </Box>
            {/* End: Options */}
        </Box >
    );
}
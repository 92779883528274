import React, { useEffect, useState } from 'react';

/**
 * Material UI
 */
import { Box, Grid, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { SectionHeader } from './controls/SectionHeader';
import { shuffleArray } from '../../../common/functions/ArrayUtils';
import { CHAR_CODE_A } from '../../../common/Constants';
import { SelectTextBox } from '../../../common/components/controls';

export const SECTION_MATCHING_EXAMPLAR = {
    title: "Title of content",
    vocabularies: [
        {
            vocabulary: "The selected vocabulary",
            explanation: "The explanation of the vocabulary"
        },
        {
            vocabulary: "The selected vocabulary",
            explanation: "The explanation of the vocabulary"
        }
    ]
};

export const generateMatchingPrompt = (numberOfQuestions, difficulty, languageCode) => {
    switch (languageCode) {
        case "en":
            return `Can you create choose ${numberOfQuestions} key vocabularies from the above content with difficulty level ${difficulty} out of 5. For each vocabulary, explain its meaning in English. Respond in the format of the examplar below only without anything else. \n ${JSON.stringify(SECTION_MATCHING_EXAMPLAR, null, 3)}`;
        case "zh":
            return `請從上文中挑選${numberOfQuestions}個詞彙（非專有名詞），難度10份之${difficulty * 2}。每個詞彙用大約二十個字來解釋，用繁體中文回答，只回答內容成以下格式. \n ${JSON.stringify(SECTION_MATCHING_EXAMPLAR, null, 3)}`;
    }
}


/**
 * sectionAttempt object:
 * - optionOrders: generate randomly as a map when it first get renders
 * - answers: a map for storing answer for each question
 */
const EMPTY_SECTION_ATTEMPT = { score: 0, answers: {} };
export function MatchingSection(props) {
    const classes = makeStyles(theme => ({
        textBlock: {
            display: "inline",
            whiteSpace: "pre-wrap",
            verticalAlign: "bottom",
            // lineHeight: "2.5em",
        },
        questionGrid: {
            display: "flex",
            alignItems: "flex-start",
            textAlign: "left"
        },
        questionNumberMarker: {
            minWidth: theme.spacing(5),
        },
        sentenceGrid: {
            display: "flex",
            alignItems: "flex-start",
            justifyItems: "flex-start"
        },
        sentenceAnswerBox: {
            width: theme.spacing(3),
            lineHeight: "1.5rem",
            textAlign: "center"
        },
        correctOrderText: {
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginLeft: theme.spacing(1),
            borderRadius: 10,
        },
        answerBlock: {
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            marginLeft: theme.spacing(1),
            borderRadius: 10,
        },
        correctAnswerText: {
            color: theme.palette.success.main,
        },
        incorrectAnswerText: {
            color: theme.palette.error.main,
        }
    }))();
    const { sectionIndex, section, updateSectionAttempt, showScore, showAnswer } = props;
    const sectionAttempt = Object.assign({}, { numberOfAnswers: section.questions.map(q => q.expressions).flat().length }, EMPTY_SECTION_ATTEMPT, props.sectionAttempt);

    const updateAnswer = (questionIndex, orderIndex, answer) => {
        let originalSectionAttempt = Object.assign({}, sectionAttempt);
        let originalAnswers = Object.assign({}, originalSectionAttempt.answers)
        originalAnswers[questionIndex] = originalAnswers[questionIndex] || [];
        originalAnswers[questionIndex][orderIndex] = answer;
        originalSectionAttempt.answers = originalAnswers;
        originalSectionAttempt.score = section.questions.map((q, qIndex) =>
            q.expressions.filter((e, eIndex) =>
                (sectionAttempt.answers[qIndex] || [])[eIndex] === String.fromCharCode(CHAR_CODE_A + sectionAttempt.optionOrders[qIndex].indexOf(e.option))
            )
        ).flat().length;
        // originalSectionAttempt.score = Object.keys(originalSectionAttempt.optionOrders).map(qIndex =>
        //     originalSectionAttempt.optionOrders[qIndex].filter((option, oIndex) =>
        //         (originalAnswers[qIndex] || [])[oIndex] === String.fromCharCode(CHAR_CODE_A + originalSectionAttempt.optionOrders[qIndex].indexOf(option)))).flat().length;
        updateSectionAttempt(sectionIndex, originalSectionAttempt);
    }

    useEffect(() => {
        // Shuffle sentences for the first time
        if (!Boolean(sectionAttempt.optionOrders)) {
            let originalSectionAttempt = Object.assign({}, sectionAttempt);
            let optionOrders = {};
            section.questions.forEach((q, qIndex) => optionOrders[qIndex] = shuffleArray([...Array(q.options.length).keys()]));
            originalSectionAttempt.optionOrders = optionOrders;
            updateSectionAttempt(sectionIndex, originalSectionAttempt);
        }
    }, [sectionAttempt.id]);

    return (
        Boolean(sectionAttempt.optionOrders) ?
            <Grid container spacing={3} className="print-table">
                <SectionHeader section={section}
                    sectionIndex={sectionIndex}
                    sectionAttempt={sectionAttempt}
                    showScore={showScore}
                    showAnswer={showAnswer} />
                {section.questions.map((question, qIndex) =>
                    <Grid key={question.id} item xs={12} className={classes.questionGrid}>
                        <Box py={1}>
                            <Typography className={[classes.textBlock, classes.questionNumberMarker]}>{qIndex + 1}. </Typography>
                        </Box>
                        <MatchingQuestion
                            classes={classes}
                            showAnswer={showAnswer}
                            question={question}
                            questionIndex={qIndex}
                            questionAttemptAnswers={sectionAttempt.answers[qIndex]}
                            questionAttemptOptionOrders={sectionAttempt.optionOrders[qIndex]}
                            updateQuestionAnswer={(eIndex, value) => updateAnswer(qIndex, eIndex, value)} />
                    </Grid>)
                }
            </Grid > : null
    )
}

const MatchingQuestion = (props) => {
    const { classes, showAnswer, question, questionAttemptAnswers, questionAttemptOptionOrders, updateQuestionAnswer } = props;
    const questionClasses = makeStyles(theme => ({
        selectedOption: {
            background: `${theme.palette.primary.main}99`,
            borderRadius: theme.spacing(1)
        },
        selectedExpression: {
            background: `${theme.palette.grey[300]}99`,
            borderRadius: theme.spacing(1)
        },
        optionBox: {
            cursor: Boolean(showAnswer) ? 'default' : 'pointer'
        },
        expressionTextInput: {
            cursor: Boolean(showAnswer) ? 'default' : 'pointer'
        }
    }))();
    const [selectedExpressionAndOption, setSelectedExpressionAndOption] = useState();

    const handleOptionSelect = (optionIndex) => {
        if (selectedExpressionAndOption?.optionIndex === optionIndex) {
            setSelectedExpressionAndOption(Object.assign({}, selectedExpressionAndOption, { optionIndex: null }));
        } else if ((selectedExpressionAndOption?.expressionIndex >= 0)) {
            let selectedOptionValue = String.fromCharCode(optionIndex + CHAR_CODE_A);
            updateQuestionAnswer(selectedExpressionAndOption?.expressionIndex, selectedOptionValue);
            setSelectedExpressionAndOption(null);
        } else {
            setSelectedExpressionAndOption(Object.assign({}, selectedExpressionAndOption, { optionIndex: optionIndex }));
        }
    }
    const handleExpressionSelect = (expressionIndex) => {
        if (selectedExpressionAndOption?.expressionIndex === expressionIndex) {
            setSelectedExpressionAndOption(Object.assign({}, selectedExpressionAndOption, { expressionIndex: null }));
        } else if (selectedExpressionAndOption?.optionIndex >= 0) {
            let selectedOptionValue = String.fromCharCode(selectedExpressionAndOption?.optionIndex + CHAR_CODE_A);
            updateQuestionAnswer(expressionIndex, selectedOptionValue);
            setSelectedExpressionAndOption(null);
        } else {
            setSelectedExpressionAndOption(Object.assign({}, selectedExpressionAndOption, { expressionIndex: expressionIndex }));
        }
    }

    return (
        <Grid container spacing={2}>
            {/* Options */}
            <Grid item xs={12}>
                {questionAttemptOptionOrders.map((optionIndex, oIndex) =>
                    <Box key={`option-${oIndex}`}
                        p={1}
                        className={[questionClasses.optionBox, classes.sentenceGrid, selectedExpressionAndOption?.optionIndex === oIndex ? questionClasses.selectedOption : null]}
                        onClick={showAnswer ? null : () => handleOptionSelect(oIndex)}>
                        <Box mr={2}>
                            <Typography>{String.fromCharCode(oIndex + CHAR_CODE_A)}.</Typography>
                        </Box>
                        <Typography>{question.options[optionIndex]}</Typography>
                    </Box>
                )}
            </Grid>
            {/* End: Options */}
            {/* Expressions */}
            <Grid item xs={12}>
                {question.expressions.map((expression, eIndex) => {
                    let correctOptionLetter = String.fromCharCode(CHAR_CODE_A + questionAttemptOptionOrders.indexOf(expression.option));
                    let isCorrect = ((questionAttemptAnswers || [])[eIndex] === correctOptionLetter);
                    return <Box display="flex" key={`answer-box-${eIndex}`} alignItems="center" ml={1} mb={1}>
                        {showAnswer ?
                            <React.Fragment key={eIndex}>
                                <Typography>{expression.text}</Typography>
                                <Box ml={2} fontWeight={800}>
                                    <Typography className={isCorrect ? classes.correctAnswerText : classes.incorrectAnswerText}>{(questionAttemptAnswers || [])[eIndex]}</Typography>
                                </Box>
                                {!isCorrect ? <Typography className={classes.correctOrderText}>{correctOptionLetter}</Typography> : null}
                            </React.Fragment> :
                            <React.Fragment>
                                <SelectTextBox key={eIndex}
                                    variant="outlined"
                                    size="small"
                                    selected={Boolean(selectedExpressionAndOption?.expressionIndex === eIndex)}
                                    style={{ marginRight: 8, width: "3.8em" }}
                                    value={(questionAttemptAnswers || [])[eIndex]}
                                    onClick={() => handleExpressionSelect(eIndex)} />
                                <Typography>{expression.text}</Typography>
                            </React.Fragment>}
                    </Box>
                })}
            </Grid>
            {/* End: Expressions */}
        </Grid>);
}
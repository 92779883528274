import React, { useState } from 'react';
import { Box, Button, Divider, Grid, Icon, makeStyles, Menu, MenuItem, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';

/**
 * Application
 */
import { GRADES, LANGUAGES, LANGUAGE_CODE_ENGLISH } from '../../Constants';
import { prepopulatedRichTextValue, RichTextEditor } from '../../../lib/slate/RichTextEditor';
import { ExpanableCheckbox, TextFieldWithSpeechRecognition } from '../../../common/components/controls';
import { uploadFileToFirebaseStorage } from '../../../common/components/controls/Media';
import { UploadDragAndDropArea } from '../../../common/components/controls/UploadDragAndDropArea';
import { AskAiCustomization, AskAIMenu } from '../../../common/functions/AIUtils';
import { add } from 'date-fns';

export const PaperAttributes = (props) => {
    const { user, paper, updatePaperAttributes, addLoading, removeLoading, copyAsNewSection } = props;
    const [attachmentConfig, setAttachmentConfig] = useState({ show: false, activeIndex: 0 });
    const [hasRichText, setRichText] = useState(Boolean(paper.richText));
    const [hasPreparationMaterial, setPreparationMaterial] = useState(Boolean(paper.preparationMaterial));
    const toggleRichText = () => {
        if (Boolean(hasRichText)) {
            updatePaperAttributes("richText", null);
        }
        setRichText(!hasRichText);
    }
    const togglePreparationMaterial = () => {
        if (Boolean(hasPreparationMaterial)) {
            updatePaperAttributes("preparationMaterial", null);
        }
        setPreparationMaterial(!hasPreparationMaterial);
    }
    const extractTextsFromFile = (bucket, fullPath, fileExtension, url) => {
        addLoading("Extracting text from file...")
        let params = new URLSearchParams();
        params.append("bucket", bucket);
        params.append("fullPath", fullPath);
        fetch(`${process.env.REACT_APP_AI_API}/ocr/convert?${params}`, {
            mode: "cors",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${user.idToken}`
            }
        }).then(response => {
            response.json().then(extractedTexts => {
                // alert(json);
                let _attachments = [
                    ...[],
                    ...(paper?.attachments || []),
                    {
                        fileExtension: fileExtension,
                        bucket: bucket,
                        fullPath: fullPath,
                        url: url,
                        pages: extractedTexts.map(text => ({ extractedText: text }))
                    }];
                updatePaperAttributes("attachments", _attachments);
                removeLoading("Extracting text from file...")
            })
        })
    }

    const updateAttachmentPage = (attachmentIndex, pageIndex, page) => {
        let _attachments = paper?.attachments || [];
        _attachments[attachmentIndex].pages[pageIndex] = page;
        updatePaperAttributes("attachments", _attachments);
    }

    const addAttachmentPageSuggestion = (attachmentIndex, pageIndex, suggestion) => {
        let _suggestions = paper?.attachments[attachmentIndex]?.pages[pageIndex]?.suggestions;
        _suggestions = [...(_suggestions || []), suggestion];
        updateAttachmentPageAttribute(attachmentIndex, pageIndex, "suggestions", _suggestions);
    }

    const removeAttachmentPageSuggestion = (attachmentIndex, pageIndex, suggestionIndex) => {
        let _suggestions = paper?.attachments[attachmentIndex]?.pages[pageIndex]?.suggestions;
        _suggestions.splice(suggestionIndex, 1);
        updateAttachmentPageAttribute(attachmentIndex, pageIndex, "suggestions", _suggestions);
    }

    const updateAttachmentPageAttribute = (attachmentIndex, pageIndex, attributeKey, attributeValue) => {
        updateAttachmentPage(
            attachmentIndex,
            pageIndex,
            Object.assign(
                {},
                paper?.attachments[attachmentIndex]?.pages[pageIndex],
                { [attributeKey]: attributeValue }));
    }

    const mergeAttachments = (attachmentIndexes = []) => {
        let firstAttachmentIndex = attachmentIndexes.shift();
        let _attachments = paper?.attachments;
        let pagesToMerge = [];
        attachmentIndexes.reverse().forEach(attachmentIndex => {
            pagesToMerge = pagesToMerge.concat(_attachments[attachmentIndex]?.pages.reverse());
            _attachments.splice(attachmentIndex, 1);
        });
        _attachments.splice(firstAttachmentIndex, 1,
            Object.assign({},
                _attachments[firstAttachmentIndex],
                { pages: [..._attachments[firstAttachmentIndex]?.pages, ...pagesToMerge.reverse()] }));
    }

    const mergeAttachmentPages = (attachmentIndex, pageIndexes = []) => {
        let firstPageIndex = pageIndexes[0];
        let _attachments = paper?.attachments;
        let _attachment = _attachments[attachmentIndex];
        let mergedPageTexts = [];
        pageIndexes.reverse().forEach(pageIndex => {
            mergedPageTexts.push(_attachment.pages[pageIndex]?.extractedText);
            _attachment.pages.splice(pageIndex, 1);
        })
        let mergedPage = {
            extractedText: mergedPageTexts.reverse().join("\n\n")
        }
        _attachment.pages.splice(firstPageIndex, 0, mergedPage);
        _attachments[attachmentIndex] = _attachment;
        updatePaperAttributes("attachments", _attachments);
    }

    const deleteAttachment = (attachmentIndex) => {
        let _attachments = paper?.attachments || [];
        _attachments.splice(attachmentIndex, 1);
        updatePaperAttributes("attachments", _attachments);
    }

    const deleteAttachmentPage = (attachmentIndex, pageIndex) => {
        let _attachments = paper?.attachments || [];
        let _attachment = _attachments[attachmentIndex];
        _attachment.pages.splice(pageIndex, 1);
        _attachments[attachmentIndex] = _attachment;
        updatePaperAttributes("attachments", _attachments);
    }

    return (
        <React.Fragment>
            <Grid item xs={12} md={6}>
                <TextFieldWithSpeechRecognition
                    label="School"
                    variant='outlined'
                    languageCode={paper.languageCode}
                    value={Boolean(paper.school) ? paper.school : ""}
                    onChange={e => updatePaperAttributes("school", e.target.value)}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextFieldWithSpeechRecognition
                    label="Subject"
                    variant='outlined'
                    languageCode={paper.languageCode}
                    value={paper.subject}
                    onChange={e => updatePaperAttributes("subject", e.target.value)}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12} md={3}>
                <Autocomplete
                    options={GRADES}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => (option.label === value.label)}
                    groupBy={option => option.group}
                    fullWidth
                    value={(GRADES.filter(g => g.label === paper.grade)[0]) || {}}
                    onChange={(e, option) => updatePaperAttributes("grade", option ? option.label : null)}
                    renderInput={(params) =>
                        <TextField {...params}
                            label="Grade"
                            variant='outlined'
                            InputLabelProps={{ shrink: true }} />
                    } />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField label="Author"
                    variant='outlined'
                    disabled={true}
                    value={paper.author.fullName}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={6} md={3}>
                <Autocomplete
                    options={LANGUAGES}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => (option.code === value.code)}
                    fullWidth
                    value={(LANGUAGES.filter(l => l.code === (paper.languageCode || LANGUAGE_CODE_ENGLISH))[0]) || {}}
                    onChange={(e, option) => updatePaperAttributes("languageCode", option ? option.code : null)}
                    renderInput={(params) =>
                        <TextField {...params}
                            label="Language"
                            variant='outlined'
                            InputLabelProps={{ shrink: true }} />
                    } />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    label="Full Score"
                    variant='outlined'
                    type="number"
                    value={paper.fullScore}
                    onChange={e => updatePaperAttributes("fullScore", parseInt(e.target.value))}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    label="Time Limit (Minutes)"
                    variant='outlined'
                    type="number"
                    value={paper.timeLimitInMinutes}
                    onChange={e => updatePaperAttributes("timeLimitInMinutes", isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value))}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <PaperTitleAttributes paper={paper} updatePaperAttributes={updatePaperAttributes} />
            <Grid item xs={12}>
                <Autocomplete
                    options={[]}
                    value={paper.taggings || []}
                    getOptionLabel={(option) => option}
                    fullWidth
                    multiple={true}
                    freeSolo={true}
                    onChange={(e, value) => updatePaperAttributes("taggings", value)}
                    renderInput={(params) =>
                        <TextField {...params}
                            label="Taggings"
                            variant='outlined'
                            InputLabelProps={{ shrink: true }} />
                    } />
            </Grid>

            {/* Attachments Area */}
            <Grid item xs={12}>
                <Box textAlign="right">
                    <ExpanableCheckbox
                        checked={attachmentConfig.show}
                        onChange={() => setAttachmentConfig(Object.assign({}, attachmentConfig, { show: !attachmentConfig.show }))}
                        label="Show Attachments" />
                </Box>

                {Boolean(attachmentConfig.show) &&
                    <Box>
                        {/* Attachments Tab */}
                        <Box py={1}>
                            <Tabs value={attachmentConfig?.activeIndex}
                                style={{ maxWidth: "90vw" }}
                                centered
                                variant="scrollable"
                                visibleScrollbar
                                scrollButtons="on"
                                allowScrollButtonsMobile="on"
                                onChange={(e, newIndex) => setAttachmentConfig(Object.assign({}, attachmentConfig, { activeIndex: newIndex }))}
                                extColor="primary"
                                indicatorColor="primary"
                                aria-label="Attachments">
                                {(paper?.attachments || []).map((attachment, aIndex) => {
                                    let fileName = attachment.fullPath.split(/[\/]+/).pop();
                                    return (
                                        <Tab key={aIndex}
                                            style={{ textTransform: "none" }}
                                            label={`File ${aIndex + 1}`}
                                            value={aIndex} />
                                    );
                                })}
                                <Tab
                                    style={{ textTransform: "none" }}
                                    label="New"
                                    value={(paper?.attachments || []).length} />
                            </Tabs>
                        </Box>

                        {/* Attachment Detail for Selected Tab */}
                        <Box my={1}>
                            {Boolean((paper?.attachments || [])[attachmentConfig?.activeIndex]) ?
                                <AttachmentDetail
                                    user={user}
                                    attachment={paper?.attachments[attachmentConfig?.activeIndex]}
                                    attachmentIndex={attachmentConfig?.activeIndex}
                                    languageCode={paper.languageCode}
                                    updateAttachmentPage={updateAttachmentPage}
                                    updateAttachmentPageAttribute={updateAttachmentPageAttribute}
                                    addAttachmentPageSuggestion={addAttachmentPageSuggestion}
                                    removeAttachmentPageSuggestion={removeAttachmentPageSuggestion}
                                    mergeWithPreviousAttachment={
                                        attachmentConfig?.activeIndex === 0 ?
                                            null :
                                            () => {
                                                mergeAttachments([attachmentConfig?.activeIndex - 1, attachmentConfig?.activeIndex]);
                                                setAttachmentConfig(Object.assign({}, attachmentConfig, { activeIndex: attachmentConfig?.activeIndex - 1 }))
                                            }
                                    }
                                    mergeAllAttachments={
                                        paper?.attachments?.length === 1 ?
                                            null :
                                            () => {
                                                mergeAttachments(Array.from(Array(paper?.attachments?.length).keys()));
                                                setAttachmentConfig(Object.assign({}, attachmentConfig, { activeIndex: 0 }))
                                            }
                                    }
                                    mergeAttachmentPages={mergeAttachmentPages}
                                    deleteAttachment={deleteAttachment}
                                    deleteAttachmentPage={deleteAttachmentPage}
                                    copyAsNewSection={copyAsNewSection}
                                    appendToPreparationMaterial={(text) =>
                                        updatePaperAttributes("preparationMaterial",
                                            [...(paper?.preparationMaterial || []),
                                            ...prepopulatedRichTextValue(Boolean(paper?.preparationMaterial) ? `\n\n${text}` : text)])}
                                    addLoading={addLoading}
                                    removeLoading={removeLoading} />
                                :
                                <UploadDragAndDropArea
                                    iconKey="camera_alt"
                                    fileTypeFilter="image/*,.pdf"
                                    onChange={event => {
                                        addLoading("Uploading file...")
                                        uploadFileToFirebaseStorage(user, event.target.files[0], (fileExtension, url, metadata) => {
                                            removeLoading("Uploading file...")
                                            extractTextsFromFile(metadata?.bucket, metadata?.fullPath, fileExtension, url);
                                        });
                                        event.target.value = null;
                                    }} />}
                        </Box>
                        {/* End: Attachment Detail for Selected Tab */}
                        {/* End: Attachments Tab */}
                    </Box>
                }
                {/* Attachments Area */}

                {/* Rich Text */}
                <Box textAlign="right">
                    <ExpanableCheckbox
                        checked={hasRichText || Boolean(paper.richText)}
                        onChange={toggleRichText}
                        label="Richtext Introduction" />
                </Box>
                {(Boolean(hasRichText) || Boolean(paper?.richText)) &&
                    <RichTextEditor variant="outlined"
                        value={paper.richText}
                        setValue={value => updatePaperAttributes("richText", value)}
                    />}
                {/* End: Rich Text */}
                {/* Preparation Material */}
                <Box textAlign="right">
                    <ExpanableCheckbox
                        checked={hasPreparationMaterial || Boolean(paper.preparationMaterial)}
                        onChange={togglePreparationMaterial}
                        label="Preparation Material" />
                </Box>
                {(Boolean(hasPreparationMaterial) || Boolean(paper?.preparationMaterial)) &&
                    <RichTextEditor variant="outlined"
                        value={paper.preparationMaterial}
                        setValue={value => updatePaperAttributes("preparationMaterial", value)}
                    />}
                {/* End: Preparation Material */}
            </Grid>
            <Box mt={5}>
                <span>&nbsp;</span>
            </Box>
        </React.Fragment >
    );
}

export const PaperTitleAttributes = (props) => {
    const { paper, updatePaperAttributes } = props;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <TextFieldWithSpeechRecognition
                    label="Title"
                    variant='outlined'
                    languageCode={paper.languageCode}
                    value={paper.title}
                    onChange={e => updatePaperAttributes("title", e.target.value)}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12}>
                <TextFieldWithSpeechRecognition
                    label="Subtitle"
                    variant='outlined'
                    languageCode={paper.languageCode}
                    value={paper.subtitle}
                    onChange={e => updatePaperAttributes("subtitle", e.target.value)}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
        </React.Fragment>
    );
}

const AttachmentDetail = (props) => {
    const { attachment, attachmentIndex, updateAttachmentPage, updateAttachmentPageAttribute, addAttachmentPageSuggestion, removeAttachmentPageSuggestion, mergeWithPreviousAttachment, mergeAllAttachments, mergeAttachmentPages, deleteAttachment, deleteAttachmentPage, ...otherProps } = props;
    const [activePageIndex, setActivePageIndex] = useState(0);
    return (
        <Box>
            <AttachmentPage
                attachment={attachment}
                page={attachment?.pages[activePageIndex]}
                pageIndex={activePageIndex}
                setActivePageIndex={setActivePageIndex}
                updateAttachmentPage={(_page) => updateAttachmentPage(attachmentIndex, activePageIndex, _page)}
                updateAttachmentPageAttribute={(aKey, aValue) => updateAttachmentPageAttribute(attachmentIndex, activePageIndex, aKey, aValue)}
                addAttachmentPageSuggestion={(suggestion) => addAttachmentPageSuggestion(attachmentIndex, activePageIndex, suggestion)}
                removeAttachmentPageSuggestion={(suggestionIndex) => removeAttachmentPageSuggestion(attachmentIndex, activePageIndex, suggestionIndex)}
                mergeWithPreviousAttachment={mergeWithPreviousAttachment}
                mergeAllAttachments={mergeAllAttachments}
                mergeAttachmentPages={(_pages) => mergeAttachmentPages(attachmentIndex, _pages)}
                deleteAttachment={() => deleteAttachment(attachmentIndex)}
                deleteAttachmentPage={() => {
                    deleteAttachmentPage(attachmentIndex, activePageIndex);
                    setActivePageIndex(() => activePageIndex - 1);
                }}
                {...otherProps} />
        </Box>
    )
}

const AttachmentPage = (props) => {
    const { user, attachment, page, pageIndex, setActivePageIndex, languageCode, updateAttachmentPage, updateAttachmentPageAttribute, addAttachmentPageSuggestion, removeAttachmentPageSuggestion, mergeWithPreviousAttachment, mergeAllAttachments, mergeAttachmentPages, deleteAttachment, deleteAttachmentPage, appendToPreparationMaterial, copyAsNewSection, addLoading, removeLoading } = props;
    const [askAiConfig, setAskAiConfig] = useState({ isCustomizing: false, customizations: {} });

    const toggleCustomizing = () => {
        if (Boolean(askAiConfig?.isCustomizing)) {
            setAskAiConfig(Object.assign({}, { isCustomizing: false, customizations: {} }));
        } else {
            setAskAiConfig(Object.assign({}, { isCustomizing: true, customizations: {} }));
        }
    }

    const updateAskAiCustomization = (type, customization) => {
        let _customizations = Object.assign({}, askAiConfig?.customizations);
        if (Boolean(customization)) {
            // Select / Update
            _customizations[type] = Object.assign({}, _customizations[type], customization);
        } else {
            // Deselect
            delete _customizations[type];
        }
        setAskAiConfig(() => Object.assign({}, askAiConfig, { customizations: _customizations }));
    }

    return (
        <Box>
            {/* Action Buttons */}
            <Box display={"flex"} justifyContent={"space-between"} p={1}>
                <Box>
                    <AttachmentPageEditMenu
                        attachment={attachment}
                        page={page}
                        attachmentUrl={attachment.url}
                        mergeWithPreviousAttachment={mergeWithPreviousAttachment}
                        mergeAllAttachments={mergeAllAttachments}
                        mergeWithPreviousAttachmentPage={(
                            pageIndex === 0) ?
                            null :
                            () => {
                                mergeAttachmentPages([pageIndex - 1, pageIndex]);
                                setActivePageIndex(pageIndex - 1);
                            }}
                        mergeAllAttachmentPages={
                            attachment?.pages.length === 1 ?
                                null :
                                () => {
                                    mergeAttachmentPages(Array.from(Array(attachment?.pages.length).keys()));
                                    setActivePageIndex(0);
                                }}
                        deleteAttachment={deleteAttachment}
                        deleteAttachmentPage={deleteAttachmentPage}
                        appendToPreparationMaterial={appendToPreparationMaterial} />
                </Box>
                <Box>
                    {/* Pagingation */}
                    <Pagination
                        count={attachment?.pages?.length || 0}
                        page={pageIndex + 1}
                        color="primary"
                        size="small"
                        onChange={(event, newPage) => setActivePageIndex(newPage - 1)} />
                    {/* End: Pagingation */}
                </Box>
                <Box>
                    {/* AI Features Menu */}
                    <AskAIMenu
                        user={user}
                        languageCode={languageCode}
                        page={page}
                        updateAttachmentPage={updateAttachmentPage}
                        addAttachmentPageSuggestion={addAttachmentPageSuggestion}
                        addLoading={addLoading}
                        removeLoading={removeLoading}
                        toggleCustomizing={toggleCustomizing} />
                    {/* End: AI Features Menu */}
                </Box>
            </Box>
            {/* End: Action Buttons */}

            {/* Ask AI Customization */}
            {Boolean(askAiConfig?.isCustomizing) &&
                <AskAiCustomization
                    user={user}
                    languageCode={languageCode}
                    page={page}
                    askAiConfig={askAiConfig}
                    toggleCustomizing={toggleCustomizing}
                    updateAskAiCustomization={updateAskAiCustomization}
                    addAttachmentPageSuggestion={addAttachmentPageSuggestion}
                    removeAttachmentPageSuggestion={removeAttachmentPageSuggestion}
                    addLoading={addLoading}
                    removeLoading={removeLoading} />}
            {/* End: Ask AI Customization */}

            {/* Page Content Editor */}
            <TextFieldWithSpeechRecognition
                multiline variant="outlined"
                languageCode={languageCode}
                value={page?.extractedText}
                onChange={event => updateAttachmentPageAttribute("extractedText", event.target.value)} />
            {/* End: Page Content Editor */}

            {/* Accept Suggestion Button */}
            {(page?.suggestions || []).map((suggestion, sIndex) => {
                let suggestedText = null;
                let suggestionTitle = null;
                switch (suggestion?.type) {
                    case "tidy-up":
                        suggestionTitle = "Tidy-up";
                        suggestedText = suggestion?.value;
                        break;
                    case "multiple-choice":
                        suggestionTitle = "Multiple Choice";
                        let multipleChoiceSection = suggestion?.value;
                        suggestion.value = Object.assign({}, multipleChoiceSection, { richText: page?.extractedText });
                        suggestedText = multipleChoiceSection?.questions.map((q, qIndex) => [...[`${qIndex + 1}. ${q?.text}`], ...q?.choices?.map(c => `- ${c?.value}`)]).flat().join("\n");
                        break;
                    case "fill-in-blank":
                        suggestionTitle = "Fill in Blank";
                        let fillInBlankSection = suggestion?.value;
                        suggestedText = fillInBlankSection?.questions.map((f, fIndex) => [...[`${fIndex + 1}. `], ...f.blocks.map(b => b.type === "answer" ? `[${b.value}]` : b.value)].join("")).join("\n");
                        break;
                    case "dictation":
                        suggestionTitle = "Dictation";
                        let dictationSection = suggestion?.value;
                        suggestedText = dictationSection?.questions.map((q, qIndex) => `${qIndex + 1}. ${q?.answer}`).join("\n");
                        break;
                    case "matching":
                        suggestionTitle = "Matching";
                        let matchingSection = suggestion?.value;
                        let options = matchingSection?.questions[0]?.options;
                        suggestedText = matchingSection?.questions[0]?.expressions.map((e, eIndex) => `${eIndex + 1}. ${e?.text} -> ${options[e?.option]}`).join("\n");
                        break;
                    default:
                        suggestionTitle = "N/A";
                        suggestedText = JSON.stringify(suggestion?.value, null, 3);
                        break;
                }
                return (
                    <Box>
                        <Box key={sIndex} textAlign="right">
                            {Boolean(suggestion?.type === "tidy-up") ?
                                <Button
                                    color='primary'
                                    onClick={() => {
                                        updateAttachmentPage(Object.assign({}, { extractedText: suggestion?.value }));
                                        removeAttachmentPageSuggestion(sIndex);
                                    }}>Accept</Button> :
                                <Button
                                    color='primary'
                                    onClick={() => {
                                        copyAsNewSection(suggestion?.type, suggestion?.value);
                                        removeAttachmentPageSuggestion(sIndex);
                                    }}>Accept</Button>}
                            {/* Discard Button */}
                            <Button
                                color='primary'
                                onClick={() => {
                                    removeAttachmentPageSuggestion(sIndex);
                                }}>Discard</Button>
                            {/* End: Discard Button */}
                        </Box>
                        {/* End: Accept Suggestion Button */}
                        {/* Suggestion */}
                        {Boolean(suggestedText) &&
                            <Box style={{ backgroundColor: "#cccccc33", whiteSpace: "pre-wrap", borderRadius: 8 }} p={1}>
                                <Typography variant='body1' style={{ fontWeight: 800 }}>{suggestionTitle}</Typography>
                                <Divider />
                                <Box my={1}>
                                    <Typography variant='body2'>{suggestedText}</Typography>
                                </Box>
                            </Box>}
                    </Box>
                )
            })}
            {/* End: Suggestion */}
        </Box >
    )
}

const AttachmentPageEditMenu = (props) => {
    const { attachment, page, attachmentUrl, mergeWithPreviousAttachment, mergeAllAttachments, mergeWithPreviousAttachmentPage, mergeAllAttachmentPages, deleteAttachment, deleteAttachmentPage, appendToPreparationMaterial } = props;
    const classes = makeStyles(theme => ({
        attachmentPageEditButton: {
            color: theme.palette.primary.main,
        }
    }))();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = event => {
        setAnchorEl(event.target);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <Button className={classes.attachmentPageEditButton} onClick={handleClick}
                endIcon={<Icon>expand_more</Icon>}>Edit</Button>
            <Menu id="attachment-page-edit-menu" keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        window.open(attachmentUrl, "_blank");
                    }}>View File</MenuItem>
                {Boolean(mergeWithPreviousAttachment) &&
                    <MenuItem
                        onClick={() => {
                            mergeWithPreviousAttachment();
                            handleClose();
                        }}>Merge with Previous Attachment</MenuItem>}
                {Boolean(mergeAllAttachments) &&
                    <MenuItem
                        onClick={() => {
                            mergeAllAttachments();
                            handleClose();
                        }}>Merge all Attachments</MenuItem>}
                {Boolean(mergeWithPreviousAttachmentPage) &&
                    <MenuItem
                        onClick={() => {
                            mergeWithPreviousAttachmentPage();
                            handleClose();
                        }}>Merge with Previous Page</MenuItem>}
                {Boolean(mergeAllAttachmentPages) &&
                    <MenuItem
                        onClick={() => {
                            mergeAllAttachmentPages();
                            handleClose();
                        }}>Merge all Pages</MenuItem>}
                <MenuItem
                    onClick={async () => {
                        deleteAttachment();
                        handleClose();
                    }}>Delete Attachment</MenuItem>
                <MenuItem
                    onClick={() => {
                        deleteAttachmentPage();
                        handleClose();
                    }}>Delete Page</MenuItem>
                <MenuItem
                    onClick={() => {
                        appendToPreparationMaterial(page?.extractedText);
                        handleClose();
                    }}>Append Page to Preparation Material</MenuItem>
            </Menu>
        </React.Fragment>
    );
}
import React, { useEffect, useState } from 'react';

/**
 * Material UI
 */
import { Box, Grid, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SectionHeader } from './controls/SectionHeader';
import { shuffleArray } from '../../../common/functions/ArrayUtils';
import { SelectTextBox } from '../../../common/components/controls';

export const SECTION_FILL_IN_BLANK_EXAMPLAR = {
    title: "Title of content",
    sentences: [
        {
            vocabulary: "The selected vocabulary",
            sentence: "The sentence formed from the vocabulary"
        },
        {
            vocabulary: "The selected vocabulary",
            sentence: "The sentence formed from the vocabulary"
        }
    ]
};

export const generateFillInBlankPrompt = (numberOfQuestions, difficulty, languageCode) => {
    switch (languageCode) {
        case "en":
            return `Can you create choose ${numberOfQuestions} key vocabularies from the above content. For each vocabulary, form a 30 words long sentence in English with difficulty level ${difficulty} out of 5. Respond in the format of the examplar below only without anything else. \n ${JSON.stringify(SECTION_FILL_IN_BLANK_EXAMPLAR, null, 3)}`;
        case "zh":
            return `請從上文中挑選${numberOfQuestions}個詞彙（非專有名詞）.並用每個這些詞彙造句二十字，難度10份之${difficulty * 2}。詞彙只會在句子中出現一次，用繁體中文回答，只回答內容成以下格式. \n ${JSON.stringify(SECTION_FILL_IN_BLANK_EXAMPLAR, null, 3)}`;
    }
}


export const SECTION_FILL_IN_BLANK_EXAMPLAR_OLD = {
    title: "Title of content",
    questions: [
        {
            blocks: [
                {
                    type: "Begining of the sentence",
                    value: "Content"
                },
                {
                    type: "answer",
                    value: "key vocabulary"
                },
                {
                    type: "Remaining part of the sentence",
                    value: "Content"
                }
            ]
        },
        {
            blocks: [
                {
                    type: "text",
                    value: "Content"
                },
                {
                    type: "answer",
                    value: "The blank to fill in"
                },
                {
                    type: "text",
                    value: "Content"
                }
            ]
        }
    ]
};


const EMPTY_SECTION_ATTEMPT = { score: 0, answers: {} };
export function FillInBlankSection(props) {
    const { sectionIndex, section, sectionAttempt, updateSectionAttempt, showScore, showAnswer } = props;
    const [selectedOptionAndAnswer, setSelectedOptionAndAnswer] = useState();
    const configurations = sectionAttempt?.configurations || section?.configurations || {};
    const classes = makeStyles(theme => ({
        option: {
            cursor: Boolean(showAnswer) ? 'default' : 'pointer'
        },
        selectedOption: {
            background: `${theme.palette.primary.main}99`,
            borderRadius: theme.spacing(1)
        },
        usedOption: {
            textDecoration: 'line-through'
        },
        selectedAnswer: {
            background: `${theme.palette.grey[300]}99`,
            borderRadius: theme.spacing(1)
        },
        textBlock: {
            display: "inline",
            whiteSpace: "pre-wrap",
            // verticalAlign: "bottom",
            lineHeight: `${2 * theme.typography.fontSize / 14}rem`,
        },
        blankBlock: {
            display: "inline",
            // verticalAlign: "bottom",
            lineHeight: `${2 * theme.typography.fontSize / 14}rem`,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            // paddingBottom: 0,
        },
        answerBox: {
            display: "flex",
            justifyContent: "space-around",
            border: "1px solid",
            borderColor: theme.palette.text.primary,
            borderRadius: 4,
        },
    }))();

    const updateAnswer = (questionIndex, blockIndex, answer) => {
        let originalSectionAttempt = Object.assign({}, sectionAttempt);
        let originalAnswers = Object.assign({}, originalSectionAttempt.answers)
        originalAnswers[[questionIndex, blockIndex]] = answer;
        originalSectionAttempt.answers = originalAnswers;
        originalSectionAttempt.score = sectionAttempt.questions.map((q, qIndex) => q.blocks.filter((b, bIndex) => b.type === "answer" && b.value === originalSectionAttempt.answers[[qIndex, bIndex]])).flat().length;
        updateSectionAttempt(sectionIndex, originalSectionAttempt);
    }

    const handleOptionSelect = (optionIndex) => {
        if (selectedOptionAndAnswer?.optionIndex === optionIndex) {
            setSelectedOptionAndAnswer(Object.assign({}, selectedOptionAndAnswer, { optionIndex: null }));
        } else if (Boolean(selectedOptionAndAnswer?.answer)) {
            updateAnswer(selectedOptionAndAnswer?.answer?.questionIndex, selectedOptionAndAnswer?.answer.blankIndex, sectionAttempt?.shuffledOptions[optionIndex]);
            setSelectedOptionAndAnswer(null);
        } else {
            setSelectedOptionAndAnswer(Object.assign({}, selectedOptionAndAnswer, { optionIndex: optionIndex }));
        }

    }

    const handleAnswerSelect = (questionIndex, blankIndex) => {
        if (selectedOptionAndAnswer?.answer?.questionIndex === questionIndex && selectedOptionAndAnswer?.answer?.blankIndex === blankIndex) {
            setSelectedOptionAndAnswer(Object.assign({}, selectedOptionAndAnswer, { answer: null }));
        } else if (Boolean(selectedOptionAndAnswer?.optionIndex >= 0)) {
            updateAnswer(questionIndex, blankIndex, sectionAttempt?.shuffledOptions[selectedOptionAndAnswer?.optionIndex]);
            setSelectedOptionAndAnswer(null);
        } else {
            setSelectedOptionAndAnswer(Object.assign({}, selectedOptionAndAnswer, { answer: { questionIndex, blankIndex } }));
        }

    }

    /**
     * Derive all possible options
     */
    useEffect(() => {
        let aggregatedSectionAttempt = Object.assign({
            numberOfAnswers: section.questions.map(q => q.blocks.filter(b => b.type === "answer")).flat().length,
        }, EMPTY_SECTION_ATTEMPT, sectionAttempt);
        // If questions not yet stored in section attempt
        if (!Boolean(sectionAttempt.questions)) {
            aggregatedSectionAttempt.questions = Boolean(section?.configurations?.shuffleQuestions) ? shuffleArray(section.questions) : section.questions;
        }
        if (Boolean(configurations?.provideOptions)) {
            let shuffledOptions = Boolean(configurations?.provideOptions) ? aggregatedSectionAttempt.questions.map(q => q.blocks.filter(b => b.type === "answer").map(b => b.value)).flat() : [];
            shuffleArray(shuffledOptions);
            aggregatedSectionAttempt.shuffledOptions = shuffledOptions;
        }
        updateSectionAttempt(sectionIndex, aggregatedSectionAttempt);
    }, [sectionAttempt.id])

    /**
     * Note: Use "print-container" class for Grid container to make print possible
     */
    return (
        <Grid container spacing={3} className="print-table">
            <SectionHeader section={section}
                sectionIndex={sectionIndex}
                sectionAttempt={sectionAttempt}
                showScore={showScore} showAnswer={showAnswer} />
            {Boolean(configurations?.provideOptions) ?
                <Grid item xs={12}>
                    <Box p={0} className={classes.answerBox} display="flex" flexWrap="wrap" justifyContent="space-between">
                        {(sectionAttempt?.shuffledOptions || []).map((a, aIndex) =>
                            <Box m={0} p={1}
                                className={[
                                    classes.option,
                                    (aIndex === selectedOptionAndAnswer?.optionIndex) ? classes.selectedOption : null,
                                    (Object.values(sectionAttempt.answers) || []).flat().includes(a) ? classes.usedOption : null
                                ]}
                                onClick={!Boolean(showAnswer) && Boolean(configurations?.provideOptions) ? () => handleOptionSelect(aIndex) : null}>
                                <Typography key={`answer-${aIndex}`}>{a}</Typography>
                            </Box>
                        )}
                    </Box>
                </Grid> : null}
            {(sectionAttempt.questions || []).map((q, qIndex) =>
                <Grid key={`question-${section.id}-${qIndex}`} item xs={12} style={{ textAlign: "left" }}>
                    <Box display="flex" justifyContent="start">
                        <Box mr={2}>
                            <Typography className={classes.textBlock}>{qIndex + 1}. </Typography>
                        </Box>
                        <Box verticalAlign="center">
                            {(q.blocks || []).map((b, bIndex) => {
                                switch (b.type) {
                                    case "text":
                                        return <Typography key={`block-${bIndex}`} component="div" className={classes.textBlock}>{b.value}</Typography>
                                    case "answer":
                                        // Need to make it uncontrolled for it to work. Don't know why
                                        return (
                                            Boolean(showAnswer) ?
                                                <Answer key={`block-${bIndex}`} attempt={sectionAttempt.answers[[qIndex, bIndex]]} answer={b.value} /> :
                                                <Blank key={`section-${section.id}-question-${qIndex}-block-${bIndex}`}
                                                    selected={selectedOptionAndAnswer?.answer?.questionIndex === qIndex && selectedOptionAndAnswer?.answer?.blankIndex == bIndex}
                                                    disabled={Boolean(configurations?.provideOptions)}
                                                    onClick={Boolean(configurations?.provideOptions) ? () => handleAnswerSelect(qIndex, bIndex) : null}
                                                    onChange={(e) => updateAnswer(qIndex, bIndex, e.target.value)}
                                                    value={sectionAttempt.answers[[qIndex, bIndex]]}
                                                    className={[classes.blankBlock]}
                                                    length={b.value.length + 5} />);
                                    default:
                                        return null;
                                }
                            })}
                        </Box>
                    </Box>
                </Grid>)}
        </Grid >
    )
}

function Answer(props) {
    const { attempt, answer } = props;
    const classes = makeStyles(theme => ({
        root: {
            textAlign: "center",
        },
        text: {
            display: "inline",
            whiteSpace: "pre-wrap",
            lineHeight: "2.5em",
        },
        attempt: {
            borderBottom: `1px solid ${theme.palette.text.primary}`,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        answerBlock: {
            // backgroundColor: `${theme.palette.error.main}CC`,
            // color: theme.palette.success.contrastText,
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
            borderRadius: 10,
        },
        correct: {
            color: theme.palette.success.main,
        },
        incorrect: {
            color: theme.palette.error.main,
        }
    }))();
    return (
        <React.Fragment>
            {Boolean(attempt) && <Typography className={[classes.text, classes.attempt, (attempt === answer ? classes.correct : classes.incorrect)]}>{attempt}</Typography>}
            {attempt === answer ? null : <Typography className={[classes.text, classes.answerBlock]}>{answer}</Typography>}
        </React.Fragment>
    )
}

const DEFAULT_LENGTH = 10;
const DEFAULT_LENGTH_BUFFER = 6;
export function Blank(props) {
    const { length, className, disabled, onClick, ...others } = props;
    const classes = makeStyles((theme) => ({
        root: {
        },
        input: {
            // width: theme.spacing((length || DEFAULT_LENGTH) + DEFAULT_LENGTH_BUFFER),
            // marginBottom: theme.spacing(0)
            // lineHeight: "5rem"
        },
        inputProps: {
            textAlign: "center",
            cursor: Boolean(disabled) ? "pointer" : "default",
            // marginBottom: theme.spacing(1)
            paddingBottom: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
            // lineHeight: "5rem"
        }
    }))();
    return (
        Boolean(onClick) ?
            <SelectTextBox
                onClick={onClick}
                style={{ width: `${((length || DEFAULT_LENGTH) + DEFAULT_LENGTH_BUFFER) * 0.8}em`, height: '2em' }}
                {...others} />
            : <TextField
                className={className || classes.input}
                inputProps={{ className: classes.inputProps, size: (length || DEFAULT_LENGTH) + DEFAULT_LENGTH_BUFFER }}
                {...others} />
    );
}
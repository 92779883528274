import React from 'react';


import { Box, Icon, makeStyles } from '@material-ui/core';

export const UploadDragAndDropArea = (props) => {
    const classes = makeStyles(theme => ({
        uploadBox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderWidth: theme.spacing(0.4),
            borderStyle: "dashed",
            borderColor: theme.palette.grey[500],
            borderRadius: theme.spacing(1),
            //maxWidth: 300,
            minWidth: 300,
            minHeight: 300,
            //aspectRatio: 1

        },
        largeIcon: {
            fontSize: 100,
            color: "#333333"
        }
    }))();
    const { iconKey, onChange, fileTypeFilter = "image/*" } = props;
    return (
        <Box className={classes.uploadBox}>
            <Box display="none">
                <input id={"button-upload-image"}
                    type="file"
                    accept={fileTypeFilter}
                    onChange={onChange} />
            </Box>
            <label htmlFor={"button-upload-image"}>
                <Icon className={classes.largeIcon}>{iconKey}</Icon>
            </label>
        </Box>
    )
}